.pets {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  background-color: #292929;
}

.pet {
  /* width: 30vw; */
  width: 500px;
  transition: width 0.3s ease-in-out;
}

.pet-image {
  transition: width 0.3s ease-in-out;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 300px;
}

.pet-stats-container {
  padding: 2px 16px;
  height: 210px;
  text-align: center;
  background-color: white;
}

.pet-name {
  margin: 10px 0;
  font-size: 250%;
}

.pet-info {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-size: 125%;
}

.pet-info li {
  display: flex;
  line-height: 1.3;
}

.info-label {
  flex: 1;
  text-align: right;
  margin-right: 20px;
}

.info {
  flex: 1;
  text-align: left;
}

.adopt-button {
  padding: 12px;
  font-size: 16px;
  transition: 0.3s;
  border: none;
  background-color: palegreen;
  cursor: pointer;
}

.adopt-button:hover {
  background-color: green;
  color: white;
}

@media screen and (min-width: 1000px) {
  .pets {
    margin: 10px;
  }
}