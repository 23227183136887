.Landing {
  color: white;
  text-align: center;
  border: 1px solid white;
  /* border-radius: 0 0 10px 10px; */
  width: 75vw;
  margin: 50px auto 0;
  font-size: 150%;
}

.Landing > h1 {
  margin: 0;
  background-color: palegreen;
  color: black;
  padding: 25px;
  margin: -1px;
}

.Landing > ol {
  list-style-position: outside;
  text-align: left;
  line-height: 1.5em;
  width: 75%;
  margin: 0 auto;
}

.Landing > a {
  display: block;
  text-decoration: none;
  background-color: white;
  padding: 16px;
  margin: auto;
  border: none;
  color: dodgerblue;
  transition: 0.3s;
}

.Landing > a:hover{
  color: black;
  transform: scaleY(1.1);
  background-color: palegreen;
  margin: 0 -1px;
}
