.UsersQueue {
  display: flex;
  flex-direction: row;
}

.UsersQueue__curr-user {
  align-self: center;
  margin-right: 25px;
  font-size: 20px;
}

.UsersQueue__dropdown-btn {
  background-color: #292929;
  color: white;
  padding: 12px 12px 12px 22px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: 0.3s;
}

.UsersQueue__dropdown {
  position: relative;
  display: inline-block;
}

.UsersQueue__dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  cursor: default;
}

.UsersQueue__dropdown-user {
  background-color: white;
  color: black;
  padding: 12px 16px;
  display: block;
}

/* .UsersQueue__dropdown:hover .UsersQueue__dropdown-content {
  display: block;
} */

.expanded {
  display: block;
}

.UsersQueue__dropdown:hover .UsersQueue__dropdown-btn {
  background-color: firebrick;
}